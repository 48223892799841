import { InjectionToken } from '@angular/core';

export type FeatureFlagGroup = {
  group: string;
  flags: Array<FeatureFlagGroup | string>;
};

export type FeatureFlagRegistry = (FeatureFlagGroup | string)[];

const FEATURE_FLAG_TOKEN: string = 'FEATURE_FLAGS';

/**
 * Injection token for providing the feature flag registry.
 */
export const FEATURE_FLAGS = new InjectionToken<FeatureFlagRegistry>(FEATURE_FLAG_TOKEN, {
  providedIn: 'root',
  factory: () => FeatureFlags
});

/**
 * A list of feature flags that will be resolved at the start of the application
 * (after the user has been authenticated, but before they enter the main application).
 * Switching firms, switching users, or refreshing the page will cause this list to be re-resolved.
 *
 * Grouping the flags is optional and subjective, but highly encouraged.
 */
const FeatureFlags: FeatureFlagRegistry = [
  {
    group: 'Accounts',
    flags: [
      'trading_eclipse_portfolio_status_icon_189133',
      'Eclipse_last_position_date_1576',
      'mass_liquidation_f211351',
      'Eclipse_Trading_Account_Grid_Outsourced_INVST_6284',
      'MS_sleeve_transfer_accounts_4823',
      'TEXP_rep_id_accounts_10111',
      'Astro_Optimization_Stack_Bar_b10577',
      'Astro_Investor_Preference_From_Account_Grid_U10822'
    ]
  },
  {
    group: 'Trades',
    flags: ['TEXP_rep_id_orders_10110']
  },
  {
    group: 'Trade Tools',
    flags: [
      {
        group: 'Pricing',
        flags: [
          'skip_quodd_10037',
        ]
      },
      {
        group: 'Tactical',
        flags: [
          'trading_tactical_rtp_f17146',
          'cash_target_reserve_b8154',
          'mass_liquidation_f211351',
          'set_aside_not_showing_b9135',
          'account_equivalents_s9143',
          'erroring_rebalancing_b11055',
          'model_tolerance_error_b11003'
        ]
      },
      {
        group: 'QuickTrade',
        flags: [
          'eclipse_quicktrade_sma_astro_9983',
        ]
      },
      {
        group: 'TLH',
        flags: [
          'tlh_alternate_preference_s1784'
        ]
      },
      {
        group: 'CashNeed',
        flags: [
          'excess_cash_distributions_s10208'
        ]
      }
    ]
  },
  {
    group: 'TradeEx',
    flags: [
      'Eclipse_Trading_MassEdit_1568',
      'solicited_discretionary_enhancements_u2852'
    ]
  },
  {
    group: 'Query Builder',
    flags: [
      'TEXP_querybuilder_primeng_10966'
    ]
  },
  {
    group: 'Network',
    flags: [
      'TEXP_ui_log_all_requests_10559'
    ]
  },
  {
    group: 'Notification',
    flags: [
      'progress_bar_enhancements_s10602'
    ]
  },
  {
    group: 'PortfolioDetails',
    flags: [
        'TEXP_portfolio_details_gain_loss_7520',
        'TEXP_portfolio_details_holdings_10542',
        'TEXP_holdings_new_columns_10914',
        'TEXP_portfolio_details_cash_7519',
    ]
  },
  {
    group:'TradeInstances',
    flags: [
      'auto_rebal_account_level_b10975'
    ]
  },
  {
    group: 'Account Preferences',
    flags: [
      'Sync_Eclipse_Cash_To_Astro'
    ]
  },
  {
    group: 'PortfolioList',
    flags: [
      'portfolios_ytd_not_showing_b9946'
    ]
  },
  {
    group:'Menu',
    flags: [
      'Eclipse_DataStatus_UI_Access_INVST_10349'
    ]
  }
];
