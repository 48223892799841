<p-sidebar [(visible)]="visible" class="opl-editor" [fullScreen]="true" [modal]="true" [blockScroll]="true"
           [autoZIndex]="true" [showCloseIcon]="false">
  <ng-template pTemplate="header">
    <p-menubar class="opl-editor-header">
      <ng-template pTemplate="start">
        <div class="inline-flex align-items-center gap-2">
          <span class="opl-editor-header-icon account">
            <p-skeleton *ngIf="!componentInstance?.account?.id" size="40px"></p-skeleton>
            <i *ngIf="!!componentInstance?.account?.id" height="40" class="fa fa-briefcase"></i>
          </span>
          <eclipse-portfolio-tree-dropdown
                                           [accountId]="componentInstance?.accountId"
                                           (entityChanged)="onTreeEntityChanged($event)"></eclipse-portfolio-tree-dropdown>
        </div>
      </ng-template>
      <ng-template pTemplate="end">
        <div class="inline-flex align-items-center" style="align-items: center;">
          <div class="text-right" *ngIf="!componentInstance?.account?.id">
            <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
            <p-skeleton width="5rem" styleClass="mb-2"></p-skeleton>
          </div>
          <div class="text-right" *ngIf="!!componentInstance?.account?.id">
            <p class="opl-editor-header-value">{{componentInstance?.account?.accountValue?.totalValue | currency }}</p>
            <p class="opl-editor-header-label">Total Value</p>
          </div>
          <p-divider layout="vertical"></p-divider>
          <div class="actionBtn btn-group pull-right">
            <button data-test-id="actions" data-target="#" label="Actions" iconPos="left" icon="fas fa-sparkles"
                    pButton class="p-button-text p-button-secondary" data-toggle="dropdown" aria-expanded="false" [disabled]="!componentInstance?.account?.id"></button>
            <ul class="dropdown-menu" #menu *ngIf="!!model && !!componentInstance">
              <li data-test-id="editDetails" *ngIf="(model.id !== undefined || model.ids !== undefined) && model.canUpdate && model?.action !== 'E'">
                <a href="javascript:void(0)" (click)="componentInstance.showPopup('Edit Details')"><i class="fas fa-fw fa-pen-to-square" aria-hidden="true"></i> Edit Account Details</a>
              </li>
              <li data-test-id="editPreferences" [hidden]="!(model.id == undefined || model.ids == undefined) || !model.canRead || !model.canUpdate || model?.action === 'AH' || model.hasSMAs || model.action === 'AA'">
                <a href="javascript:void(0)" (click)="componentInstance.showPopup('PREFERENCES')"><i class="fas fa-fw fa-list-check" aria-hidden="true"></i> Edit Preferences</a>
              </li>
              <li data-test-id="sleeveTransfer" [hidden]="!(model.id == undefined || model.ids == undefined) || !model.displaySleeveTransfer || !model.canRead || !model.canUpdate || model?.action === 'AH' || model.hasSMAs || model.action === 'AA'">
                <a href="javascript:void(0)" (click)="componentInstance.showPopup('SLEEVE_TRANSFER')"><i class="fas fa-fw fa-swap" aria-hidden="true"></i> Sleeve Transfer</a>
              </li>
              <li data-test-id="assignPortfolio" [hidden]="!model.canUpdate || model.isDisabled == 1 || model.typeId == 1 || model?.action === 'AH' || model.action === 'AA'">
                <a href="javascript:void(0)" (click)="componentInstance.showPopup('Assign')"><i class="fas fa-fw fa-briefcase-arrow-right" aria-hidden="true"></i> Assign Portfolio</a>
              </li>
              <li data-test-id="assignMAC" *ngIf="model.displayMACFund && model.action ==='V'">
                <a href="javascript:void(0)" (click)="componentInstance.showPopup('BlendedFund')"><i class="fas fa-fw fa-arrows-turn-to-dots" aria-hidden="true"></i> Assign a MAC Fund</a>
              </li>
              <li data-test-id="equivalent" id="equivalent" class="dropdown-submenu pull-left" [hidden]="!model.isPreferenceEditPermission || model?.action !== 'L'">
                <a href="javascript:void(0)"><i class="fas fa-fw fa-equals" aria-hidden="true"></i> Account Equivalents</a>
                <ul class="dropdown-menu">
                  <li data-test-id="editPreferences">
                    <a href="javascript:void(0)" (click)="componentInstance.showPopup('IMPORT_EXPORT_EQUIVALENTS')">Import/Export Equivalents</a>
                  </li>
                  <li>
                    <a href="javascript:void(0)" [routerLink]="['/eclipse/admin','importlogs']">Import History</a>
                  </li>
                </ul>
              </li>
              <li data-test-id="smaWeightings" [hidden]="!(model?.isTrue === 'Yes') || model?.action === 'AH'">
                <a href="javascript:void(0)" (click)="componentInstance.showPopup('SMA Weightings')"><i class="fas fa-fw fa-scale-balanced" aria-hidden="true"></i> SMA Weightings</a>
              </li>
              <li role="separator" class="divider"></li>
              <li data-test-id="tradeTools" *ngIf="model?.action !== 'AH' && !(model.id == undefined && model?.action === 'V') && model.hasTradables" class="dropdown-submenu pull-left">
                <a href="javascript:void(0)"><i class="fas fa-fw fa-display-chart-up-circle-dollar" aria-hidden="true"></i> Trade Tools</a>
                <ul class="dropdown-menu">
                  <li data-test-id="cashNeeds" *ngIf="allowCashNeeds()">
                    <a href="javascript:void(0)" (click)="componentInstance.showPopup('CashNeed')">Cash Need</a>
                  </li>
                  <li data-test-id="cashNeedsPortfolio" *ngIf="allowCashNeedsPortfolio()">
                    <a href="javascript:void(0)" (click)="componentInstance.showPopup('CashNeeds-Portfolio')">Cash Needs (Portfolio Level)</a>
                  </li>
                  <li data-test-id="globalTrades" *ngIf="model?.globalTradesToolPermission">
                    <a href="javascript:void(0)" (click)="componentInstance.showPopup('GlobalTrades')">Global Trades</a>
                  </li>
                  <li data-test-id="optimize" *ngIf="model.astroEnabled">
                    <a href="javascript:void(0)" (click)="componentInstance.showPopup('OPTIMIZE')">Optimize</a>
                  </li>
                  <li data-test-id="optionTrading" *ngIf="model.optionsTradeToolPermission && (!model.sleeves?.length || model.sleeves[0].sleeveType === 'Normal')">
                    <a href="javascript:void(0)" (click)="componentInstance.showPopup('OptionTrading')">Option Trading</a>
                  </li>
                  <li data-test-id="raiseCash" *ngIf="model.raiseCashToolPermission">
                    <a href="javascript:void(0)" (click)="componentInstance.showPopup('RaiseCash')">Raise Cash</a>
                  </li>
                  <li data-test-id="rebalancer" *ngIf="allowRebalance()">
                    <a  href="javascript:void(0)" (click)="componentInstance.showPopup('Rebalancer')"> Rebalancer </a>
                  </li>
                  <li data-test-id="rebalancePortfolio" *ngIf="allowRebalancePortfolio()">
                    <a href="javascript:void(0)" (click)="componentInstance.showPopup('Rebalancer-Portfolio')">Rebalance (Portfolio Level)</a>
                  </li>
                  <li data-test-id="rebalanceSleeve" *ngIf="allowRebalanceSleeves()">
                    <a  href="javascript:void(0)" (click)="componentInstance.showPopup('Rebalancer')">Individual Sleeve Rebalance</a>
                  </li>
                  <li data-test-id="spendCash" *ngIf="model?.spendCashToolPermission">
                    <a href="javascript:void(0)" (click)="componentInstance.showPopup('SpendCash')">Spend Cash</a>
                  </li>
                  <li data-test-id="tacticalTool" *ngIf="(model.id || model.ids) && model?.tacticalTradeToolPermission">
                    <a href="javascript:void(0)" (click)="componentInstance.showPopup('TacticalTool')">Tactical Tool</a>
                  </li>
                  <li data-test-id="taxHarvesting" *ngIf="model?.taxHarvestingToolPermission">
                    <a href="javascript:void(0)" (click)="componentInstance.showPopup('TaxHarvesting')">Tax Harvesting</a>
                  </li>
                  <li data-test-id="tradeToTarget" *ngIf="model?.tradeToTargetToolPermission">
                    <a id="tradeToTarget" href="javascript:void(0)" (click)="componentInstance.showPopup('TradeToTarget')">Trade To Target</a>
                  </li>
                  <li data-test-id="liquidate" *ngIf ="model?.liquidateToolPermission && massLiquidateFF">
                    <a id="liquidate" href="javascript:void(0)" (click)="componentInstance.showPopup('Liquidate')">Liquidate</a>
                  </li>
                </ul>
              </li>
              <li role="separator" class="divider"></li>
              <li data-test-id="viewDetails" *ngIf="model.id !== undefined && model.canRead && !(model.action === 'V' || model.action === 'AH' || model.action === 'AA')">
                <a href="javascript:void(0)" (click)="componentInstance.showPopup('View Details')"><i class="fas fa-fw fa-briefcase" aria-hidden="true"></i> Account Details</a>
              </li>
              <li data-test-id="viewPastTransactions" [hidden]="model.id == undefined || model?.action !== 'V' || model?.action === 'AH'">
                <a href="javascript:void(0)" (click)="viewPastTransaction(model)"><i class="fas fa-fw fa-clock-rotate-left" aria-hidden="true"></i> Past Transactions</a>
              </li>
              <li data-test-id="viewSystematics" *ngIf="model.id || (model.ids && model.ids.length === 1)">
                <a href="javascript:void(0)" (click)="componentInstance.showPopup('ViewAccountInOC')"><i class="fas fa-fw fa-briefcase" aria-hidden="true"></i> View Account in OC</a>
              </li>
              <li data-test-id="viewSystematics" *ngIf="model.id || (model.ids && model.ids.length === 1)">
                <a href="javascript:void(0)" (click)="componentInstance.showPopup('AccountSystematics')"><i class="fas fa-fw fa-user-robot" aria-hidden="true"></i> Account's Systematics</a>
              </li>
              <li data-test-id="viewAuditHistory" [hidden]="model.id === undefined || !model.canRead || model?.action === 'AH' || model.action === 'AA'">
                <a [routerLink]="['/eclipse', 'account', 'audit' , model.id]"><i class="fas fa-fw fa-history" aria-hidden="true"></i> Audit History</a>
              </li>
              <li data-test-id="viewHoldings" [hidden]="model.id === undefined || !model.canRead || model?.action === 'AH' || !model.isUserHasHoldingPrev">
                <a [routerLink]="['/eclipse', 'holding', 'list' , 'account', model.id, model.routeParam]"><i class="fas fa-fw fa-list-alt" aria-hidden="true"></i> Holdings</a>
              </li>
              <li data-test-id="viewHoldings" [hidden]="model.id === undefined || !model.canRead">
                <a href="javascript:void(0)" (click)="componentInstance.showPopup('Portfolio')"><i class="fas fa-fw fa-cubes" aria-hidden="true"></i> Portfolio</a>
              </li>
              <li data-test-id="viewPendingTrades" [hidden]="!( (model.id != undefined || model.ids != undefined) && model.filterTypeId == model.enumValue) || model?.action != 'L' || !model.canRead || model?.action === 'AH'">
                <a href="javascript:void(0)" (click)="componentInstance.showPopup('PendingTrans')"><i class="far fa-fw fa-clock" aria-hidden="true"></i> Pending Trades</a>
              </li>
              <li data-test-id="syncFromOrionAccount" [hidden]="(model.id === undefined && model.ids === undefined)">
                <a href="javascript:void(0)" (click)="componentInstance.showPopup('Sync From Orion')"><i class="far fa-fw fa-arrow-right-from-arc" aria-hidden="true"></i> Sync From Orion</a>
              </li>
            </ul>
          </div>
          <p-divider layout="vertical"></p-divider>
          <button type="button" pButton pRipple icon="pi pi-times"
                  class="p-button-text p-button-secondary" (click)="onClose()"></button>
        </div>
      </ng-template>
    </p-menubar>
  </ng-template>
  <div class="opl-editor-content-container">
    <div class="opl-editor-content">
      <ng-template #entityhost></ng-template>
    </div>
  </div>
</p-sidebar>
